




















import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class LoanLockBanner extends Vue {
  @Prop({ default: '' }) public docName: any;
  @Prop({ default: 'Someone else' }) public updatedBy: any;
  @Prop({ default: 'document' }) public docType: any;

  public handlePageReload() {
    location.reload();
    return false;
  }
}
