

import { Component, Vue, Prop } from "vue-property-decorator";
import { BASE_API_URL, EventBus as events } from "@/config";
import Axios from "axios";
import { PopoverPlugin } from "bootstrap-vue";
import CommunicationMixin from "@/mixins/communication.mixin";
import { mixins } from "vue-class-component";
import { HollowDotsSpinner } from "epic-spinners";
import CommonMixin from "@/mixins/common.mixin";
import UpdateUserInfo from "../UpdateUserInfo.vue";
import CommunicationPopup from "@/views/CommunicationPopup.vue";
import { EventBus } from "@/views/CommunicationPopup.vue";
import DisplayFiles from "@/views/DisplayDocument.vue";
import UpdateClosingAndRateDate from "@/views/updateClosingAndRateLockDate.vue"
import striptags from "striptags";
import { ALLOWED_FILE_EXTENSIONS } from "@/constants";
import OverlayInput from "@/views/OverlayInput.vue";
import LoanLockBanner from "../../views/LoanLockBanner.vue";
import ConfirmUnassignBorrowerModal from "@/views/ConfirmUnassignBorrowerModal.vue";

Vue.use(PopoverPlugin);

@Component({
  components: {
    HollowDotsSpinner,
    UpdateUserInfo,
    CommunicationPopup,
    DisplayFiles,
    UpdateClosingAndRateDate,
    OverlayInput,
    LoanLockBanner,
    ConfirmUnassignBorrowerModal,
  }
})
export default class PipeLineHeaderComponent extends mixins(
  CommunicationMixin,
  CommonMixin
) {
  public allowedFileExtensions = ALLOWED_FILE_EXTENSIONS;
  @Prop()
  public propData: object;
  public componentData: any = null;
  public clickedOutside = false;
  public newComment = null;
  public pathPrefix = null;
  public currentTask = null;
  public errorInComment = false;
  public fileChange = false;
  public countFiles = 0;
  public localFiles = [];
  public personalInfo = [];
  public attachedFiles: any = [];
  public toggleShow = false
  public showClosingandRateDate =false
  public updateDatesObj:any ={}
  public showPopUpForInfo = false;
  public loanData=null;
  public lenderData: any = null;
  public allLoanTasks = [];
  public loanNumberToUpdate = '';
  public inProgress = false;
  public loanLockBannerIsOpened = false;
  public updateBy = '';

  public selectedCommunication: any = {};

  public get userType() {
    return this.$store.state.sessionObject.type;
  }

  //Function for play learn more video
   private playWeLearnVideo(){
    events.$emit('openWeLearn', {taskName: 'Communications' , videoUrl: 'https://wemlo.s3.amazonaws.com/Common/taskdemo/BorrowersProfileCommunication.mp4'})
}
  /***********************************************************************************************************************************
   *    this function used for update information in pipline. function called from UpdateUserInfo component                          *
   ***********************************************************************************************************************************/
  public updateBorrowerInfo(updatedData) {
    updatedData.forEach((element, i) => {
      this.componentData.borrowerInfo[i].email = element.email;
      this.componentData.borrowerInfo[i].cellPhone = element.cellPhone;
      this.componentData.borrowerInfo[i].firstName = element.firstName;
      this.componentData.borrowerInfo[i].lastName = element.lastName;
    });
    this.componentData = JSON.parse(JSON.stringify(this.componentData));
  }
  
  /******************************************************************************************************************************
   *        function used for update borrower(Name, Email, Phone Number) and Lender Info                                        *
   ******************************************************************************************************************************/
  showPersonalDetailModal(modalName) {
    let lenderData = {
      name: this.componentData.lenderName,
      id: this.componentData.lenderId
    };
    let loanInfo = {
      loanTxnId: this.componentData.loanTxnId,
      modalName: modalName,
      userType: "Borrower",
      brokerId: this.componentData.brokerId
    };
    this.personalInfo = this.componentData.borrowerInfo.map(a => {
      return { ...a, ...loanInfo, lenderName: lenderData };
    });
    this.$refs.updateUserStatus["openModalOrClose"](this.personalInfo);
  }

  onLenderUpdated() {
    this.triggerReloadRouter();
  }

  getCurrentTaskName() {
    const pathRoute = this.$route.path;
    this.pathPrefix = ((pathRoute !='/processor/borrower-detail') && (pathRoute !='processor/loan-estimate')) ? 'Tasks > ' : 'Open Tasks';
    const match = this.allLoanTasks.find((task) => {
        if (pathRoute.split('/').indexOf(task.url) > -1){return 1;}
    });
    this.currentTask = match ? match.name : '';
  }

  showClosingAndRate(fieldName){
    this.showClosingandRateDate = true
    this.updateDatesObj ={
      fieldName:fieldName,
      borrower:this.componentData
  
    }
   
  }

  redirectToLenderSite(event, data){
     if(data && data.lenderWebSite){
        window.open(data.lenderWebSite, '_blank');
     }
     else{
        console.log('Lender info is missing for a website redirect.')
        event.preventDefault();
     }
  }

  public toggleOverLayPopOver(clickedOutside = false) {
    let overlay: any = this.$refs.overlay;
    if (overlay.style.display == "block") {
      overlay.style.display = "none";
      this.toggleCommentsNav("none"); //However this function don't work now.Actually this function itself is not being called.
      this.closeNavForCommunication();
    } else {
      overlay.style.display = "block";
    }

    if (clickedOutside) {
      this.clickedOutside = true;
    } else {
      this.clickedOutside = false;
    }
  }

  public openCommentModal() {
    this.$modal.show("commentModal");
    this.attachedFiles = [];
    this.countFiles = this.attachedFiles.length;
    this.newComment = null;
  }
  // ---------------Save Function for Attachment-------------/
  storeAttachments(event) {
    this.attachedFiles = [];
    Object.values(event.target.files).forEach(element => {
      this.attachedFiles.push(element);
      this.localFiles = this.attachedFiles;
    });
    this.countFiles = this.attachedFiles.length;
    if (event.target.files.length > 0) {
      this.fileChange = true;
    }
  }
  // ------------End Of Save Function for Attachment-----------/
  // ------------Save function for Adding  Comment-------------/
  public async addComment() {
    try {
      if (!this.newComment) {
        this.errorInComment = true;
        return;
      }
      this.$store.state.wemloLoader = true;
      let formData = new FormData();
      this.attachedFiles.forEach(e => {
        formData.append("file", e);
      });
      formData.append("loanTxnId", JSON.stringify(this.$route.query.id));
      formData.append("comment", this.newComment);
      formData.append("source", "ProcessorComment");

      formData.append("brokerId", this.componentData.brokerId);
      formData.append("mloId", JSON.stringify(this.componentData.mloId));
      formData.append("userType", this.$userType);
      let response = await Axios.post(
        BASE_API_URL + "wemloprocessor/addComment",
        formData);
      if (!this.comments) this.comments = [];
      this.comments.unshift(response.data.comment);
      this.$modal.hide("commentModal");
      this.$store.state.wemloLoader = false;
      this.$snotify.success("Comment added successfully.");
      this.newComment = null;
      this.attachedFiles = [];
      this.countFiles = 0;
    } catch (error) {
      this.$store.state.wemloLoader = false;
      console.log(error.response);
    }
  }
  // --------------End of Save Function for Adding Comment-------------/
  /**************************************************************************************************
   *                               DELETE FILES FROM COMMON COMPO                                    *
   **************************************************************************************************/
  public deleteDocs(from) {
    try {
      this[from.deleteData].splice(from.index, 1);
      this.countFiles--;
    } catch (error) {
      console.log(error);
    }
  }
  //------------------------Displaying Attachment-----------------------/
  public displayDocument(allFiles, isDeleteButton, deleteData) {
    this.$refs.dfd["setInitialValuesForLocalFiles"](
      allFiles,
      isDeleteButton,
      deleteData
    );
  }

  public removeTagsAndTruncate(strg, length, ending) {
        if (strg) {
            let str = striptags(strg, [], " ").replace(/\s\s+/g, " ");
            if (length == null) {
                length = 100;
            }
            if (ending == null) {
                ending = "...";
            }
            if (str.length > length) {
                return str.substring(0, length - ending.length) + ending;
            } else {
                return str;
            }
        } else return "";
    }
  public setUpdatedClosingDate(taskOption){
    try {  
      this.componentData.updatedClosingDate = taskOption.updatedClosingDate;
      this.componentData.updatedRateLockDate = taskOption.updatedRateLockDate;
      this.componentData.updatedRateExpirationDate = taskOption.updatedRateExpirationDate
      this.componentData.updatedRateLockExtensionDate = taskOption.updatedRateLockExtensionDate
      this.componentData.updatedRateType = taskOption.updatedRateType
       this.componentData.loanNumber = taskOption.loanNumber
    } catch (error) {
      console.log(error);
    }
  }
  public async updateBorrowerInfoData(data) {
    if (data.length>0 && data[0].modalName == "lenderInfoUpdate") {
      // In case if lender was changed via dropdown
      this.componentData.lenderName = data[0].lenderName.name;
      this.lenderData.lenderName = data[0].lenderName.name;
    } else {
      // Other personal info changed
      this.componentData.borrowerInfo[this.componentData.primaryBorrowerIndex].email = data.borrowerInfo[this.componentData.primaryBorrowerIndex].personalInfo.contactInformation.email;
      this.componentData.borrowerInfo[this.componentData.primaryBorrowerIndex].cellPhone = data.borrowerInfo[this.componentData.primaryBorrowerIndex].personalInfo.contactInformation.cellPhone;
      this.componentData.borrowerInfo[this.componentData.primaryBorrowerIndex].firstName = data.borrowerInfo[this.componentData.primaryBorrowerIndex].personalInfo.name.firstName;
      this.componentData.borrowerInfo[this.componentData.primaryBorrowerIndex].lastName = data.borrowerInfo[this.componentData.primaryBorrowerIndex].personalInfo.name.lastName;
    }
  }

  /**************************************************************************************************
   *                               GET LENDER WEBSITE  BY ID                                    *
   **************************************************************************************************/

  public async getLenderById(){
    try {
      let response = await Axios.get(
       BASE_API_URL + "wemloprocessor/getLenderSiteById",{
        params:{
            lenderId:this.componentData.lenderId
        }
      })
      this.lenderData = response.data;
    } catch (error) {
      console.log(error)
    }
  }

  public async getAllLoanDetail(){
    try {
      let response = await Axios.get(
        BASE_API_URL + "common/getAllLoanDetail", {
          params:{
            loanTxnId:this.componentData.loanTxnId
          }
        }
      )
      this.loanData =response.data.loanDetails;
    } catch (error) {
      console.log(error)
    }
  }

 /**************************************************************************************************
   *                             DEEP LINK FEATURE - GET ALL TASKS BY LOAN ID                    *
  **************************************************************************************************/
  public async getAllTasksByLoanId(){
    try {
      let response = await Axios.get(
        BASE_API_URL + "wemloprocessor/getAllTasksByLoanId", {
          params: {
            loanTxnId: this.componentData.loanTxnId
          }
        }
      )
      this.allLoanTasks = response.data;
      this.getCurrentTaskName();
    } catch (error) {
      console.log(error)
    }
  }

  public openUpdateLoanNumberModal() {
      this.loanNumberToUpdate = this.componentData.loanNumber;
      this.$modal.show('loanNumberUpdate');
  }

  public closeUpdateLoanNumberModal() {
      this.$modal.hide('loanNumberUpdate');
  }

    public async updateLenderLoanNumber() {
        try {
            this.inProgress = true;
            let response = await Axios.post(
            BASE_API_URL + "wemloprocessor/updateLoanNumber", {
                loanTxnId: this.componentData.loanTxnId,
                loanNumber: this.loanNumberToUpdate,
            });
            this.$modal.hide('loanNumberUpdate');
            if (response.status == 200){
                this.componentData.loanNumber = this.loanNumberToUpdate;
                this.$snotify.success("Lender Loan Number Updated.");
            } else {
                this.$snotify.error("Error Updating Lender Loan Number.");
            }
        } catch (error) {
            console.log(error)
        } finally {
            this.inProgress = false;
        }
    }

  triggerReloadRouter() {
    this.$emit('reload');
  }

  public openUnassignBorrowerFromCommunicationModal(communication) {
    this.selectedCommunication = communication;
    this.$modal.show('confirmUnassignBorrower');
  }

  public onUnassignBorrowerFromCommunication() {
    this.selectedCommunication = {};
    this.getCommunicationHistory(this.componentData.loanTxnId);
  }

  mounted() {
    events.$on("docUpdatedBy", (data) => {
      this.updateBy = data;
      this.loanLockBannerIsOpened = true;
    });
    this.componentData = JSON.parse(JSON.stringify(this.propData));
    this.componentData.lenderId && this.getLenderById();
    this.getAllTasksByLoanId();
    EventBus.$on("deleteDocs", obj => {
      this.deleteDocs(obj);
    });
  }

  public onLoanLockBannerClose() {
    this.loanLockBannerIsOpened = false;
  }
}
