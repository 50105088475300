











































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { BASE_API_URL } from "@/config";
import Datepicker from "vuejs-datepicker";
import Axios from "axios";
import moment, { updateLocale } from "moment";
import { ALLOWED_FILE_EXTENSIONS } from "@/constants";

import DisplayFiles from "@/views/DisplayDocument.vue";
@Component({
  components: {
    Datepicker, DisplayFiles
  }
})
export default class UpdateClosingAndRateLockDateComponent extends Vue {
  public allowedFileExtensions = ALLOWED_FILE_EXTENSIONS;
  
  @Prop()
  public propData;

  @Prop()
  public showClosingAndRateDates;
  public taskOption: any = {
    lockType: false,
    rateLockDate: null,
    updatedRateExpirationDate: null,
    extendedDate: null,
    loanTxnId: null,
    extensionType: false,
    closingDate: null,
     loanNumber: null

  };
  public showLockRateDate = false;
  public state: any = {};
  public editType = null;
  public loanAudit = {
    loanTxnId: null,
    source: "Processor Dashboard",
    updateOn: new Date(),
    updatedBy: this.$userId,
    activity: {
      closingDate: null,
      rateType: null,
      rateLockExpirationDate: null,
      rateLockDate: null,
      extendedDate: null,
      loanNumber: null
    }
  };
  public initialRateLockDate = null
  public loader = false
  public attachment: any = [];
  public commentAttachment = [];
  public counts = 0;
  public count = 0;
  public rateLockDateChange = false

  @Watch("showClosingAndRateDates")
  showHideModal() {
    if (this.showClosingAndRateDates) {
      this.setFieldName();
      this.disablingToDate();
    } else {
      this.$modal.hide("editLockDate");
    }
  }

  public onChangeClosingDate(closingDate) {
    /**
     * Set the closing date as utc 12:00 hours
     */
    if (closingDate instanceof Date) {
      this.taskOption.closingDate = moment(closingDate).utc().hours(12).minutes(0).toDate();
    }

    this.disablingToDate();
  }

  public async submitUpdatedData() {
    try {
      this.loader = true;
      let formValid = await this.$validator.validateAll();
      if (!formValid) {
        this.loader = false;
        return;
      }
      if (this.editType=='lockRate' &&
        this.taskOption.lockType == "Float Rate") {
        this.loanAudit.loanTxnId = this.taskOption.loanTxnId;
        this.loanAudit.activity.rateType = this.taskOption.lockType;
        this.taskOption.extendedDate = null;
        this.taskOption.updatedRateExpirationDate = null;
        this.taskOption.rateLockDate = null;
      }
      //**********************************************
      if (this.editType == "closing") {
        this.loanAudit.activity.closingDate = this.taskOption.closingDate;
        this.loanAudit.loanTxnId = this.taskOption.loanTxnId;
        (this.taskOption.lockType = false),
          (this.taskOption.rateLockDate = null),
          (this.taskOption.updatedRateExpirationDate = null),
          (this.taskOption.extendedDate = null),
          (this.taskOption.extensionType = false);
      } 
      if(this.editType == "loanNumber"){
         this.loanAudit.activity.loanNumber = this.taskOption.loanNumber;
        this.loanAudit.loanTxnId = this.taskOption.loanTxnId;
        
      }
      
     if (this.editType=='lockRate' && this.taskOption.lockType == "Lock Rate" ) {
        this.loanAudit.loanTxnId = this.taskOption.loanTxnId;
        this.loanAudit.activity.rateType = this.taskOption.lockType;
        this.loanAudit.activity.rateLockExpirationDate = this.taskOption.updatedRateExpirationDate;
        this.loanAudit.activity.rateLockDate = this.taskOption.rateLockDate;
        this.loanAudit.activity.extendedDate = this.taskOption.extendedDate;
        this.taskOption.closingDate = null;
      }
      if (this.taskOption.lockType == "Lock Rate" && this.rateLockDateChange == true) {
        await this.rateLockFileUpload()
      }

      //**********************************************
      let response = await Axios.post(
        BASE_API_URL + "wemloprocessor/editLockDate",
        {
          taskOption: this.taskOption,
          loanAudit: this.loanAudit,
          type: this.editType
        });
      let updatedBorrowerData = response.data.updatedBorrower;
      this.loader = false;
      this.$snotify.success("Saved successfully");
      this.$emit("closeModal", false);
      this.$emit("callMountFunction");
      this.$emit("setUpdatedClosingDate", updatedBorrowerData);
      this.loanAudit.activity.closingDate=null;
      this.loanAudit.activity.rateType=null;
      this.loanAudit.activity.rateLockExpirationDate=null;
      this.loanAudit.activity.rateLockDate=null;
      this.loanAudit.activity.extendedDate=null;
      this.loanAudit.activity.loanNumber=null;
      this.initialRateLockDate = updatedBorrowerData.updatedRateLockDate;
      this.rateLockDateChange = false;
    } catch (error) {
      this.loader = false;
      console.log(error);
    }
  }

  public disablingToDate() {
    this.checkRateLockDate()
    if (this.taskOption.rateLockDate != null) {
      let fromDateOne = new Date(this.taskOption.rateLockDate);
      this.state.disabledDatesFromOne.to = new Date(
        fromDateOne.setDate(fromDateOne.getDate() + 1)
      );
    }
    if (this.taskOption.updatedRateExpirationDate != null) {
      let fromDateTwo = new Date(this.taskOption.updatedRateExpirationDate);
      this.state.disabledDatesFromTwo.to = new Date(
        fromDateTwo.setDate(fromDateTwo.getDate() + 1)
      );
    }
  }

  hideModel() {
    this.$emit("closeModal", false);
    this.$modal.hide("editLockDate");
    this.showLockRateDate = false;
    this.taskOption.lockType = false;
    this.taskOption.rateLockDate = null;
    this.initialRateLockDate = null;
    this.taskOption.updatedRateExpirationDate = null;
    this.taskOption.extendedDate = null;
    this.taskOption.loanTxnId = null;
    this.taskOption.extensionType = false;
    this.taskOption.loanNumber=null;
    this.attachment = [];
    this.commentAttachment = [];
    this.counts = 0;
    this.count = 0
    this.rateLockDateChange = false
    this.restoreDisableDates();
  }
  public restoreDisableDates() {
    let date = new Date().setDate(new Date().getDate() - 1);
    this.state = {
      disabledDatesFrom: {
        to: new Date(date)
      },
      disabledDatesFromOne: {
        to: new Date(date)
      },
      disabledDatesFromTwo: {
        to: new Date(date)
      },
      disabledDatesTo: {
        to: new Date(date)
      }
    };
  }
  public deleteDoc(from) {
    try {
      this[from.deleteData].splice(from.index, 1);
      this.counts--;
      this.count--;
    } catch (error) {
      console.log(error);
    }
  }
  public async setFieldName() {
    this.editType = this.propData.fieldName;
    this.$modal.show("editLockDate");

    if (this.propData.borrower.updatedRateType == "Lock Rate") {
      this.taskOption.lockType = "Lock Rate";
      this.showLockRateDate = true;
      let response = await Axios.post(
        BASE_API_URL + "loan/getRateLockFile",
        {
          userId: this.$userId,
          loanTxnId: this.propData.borrower.loanTxnId
        });

      if (response.data.file) {
        this.attachment = response.data.file.fileHistory[response.data.file.fileHistory.length - 1].files
        this.counts = this.attachment ? this.attachment.length : 0;
        Object.values(this.attachment).forEach(e => {
          this.commentAttachment.push(e)
        });
      } else { this.commentAttachment = [] }
    }
    if (this.propData.borrower.updatedRateType == "Float Rate") {
      this.taskOption.lockType = "Float Rate";
    }
    if (this.propData.borrower.updatedRateLockExtensionDate != null) {
      this.taskOption.extensionType = true;
    }
    if (this.editType == "closing") {
      this.taskOption.closingDate = this.propData.borrower.updatedClosingDate;
      //  console.log(this.taskOption,"dsdds")
      //console.log(this.propData,"abcxxx");
    }
    if(this.editType == 'loanNumber'){
      this.taskOption.loanNumber = this.propData.borrower.loanNumber
    }
    this.taskOption.rateLockDate = this.propData.borrower.updatedRateLockDate;
    this.initialRateLockDate = this.propData.borrower.updatedRateLockDate
    this.taskOption.updatedRateExpirationDate = this.propData.borrower.updatedRateExpirationDate;
    this.taskOption.loanTxnId = this.propData.borrower.loanTxnId;
    this.taskOption.extendedDate = this.propData.borrower.updatedRateLockExtensionDate;
    this.rateLockDateChange = false
  }
  public checkRateLockDate() {
    if (this.taskOption.rateLockDate === this.initialRateLockDate) {
      this.rateLockDateChange = false
    }

    else {
      this.rateLockDateChange = true
    }
  }

  public changeValue(val) {
    this.rateLockDateChange=false;
    if (val == "Lock Rate") {
      this.showLockRateDate = true;
    } else {
      this.showLockRateDate = false;
      this.taskOption.rateLockDate = null;
      this.taskOption.updatedRateExpirationDate = null;
      this.taskOption.extendedDate = null;
      this.taskOption.extensionType = false;
    }
  }
  public async rateLockFileUpload() {
    try {
      let formData = new FormData();
      if (this.attachment) {
        Object.values(this.attachment).forEach((e, i) => {
          formData.append("file", this.attachment[i]);
        });
      }


      let data: any = {
        loanTxnId: this.propData.borrower.loanTxnId,
        rateType: this.taskOption.lockType,
        userType: this.$userType
      };
      formData.append("data", JSON.stringify(data));

      await Axios.post(
        BASE_API_URL + "loan/rateLockFileUpload",
        formData);
      this.commentAttachment = [];
      this.counts = 0;
      this.count = 0
      this.attachment = []
    }

    catch (error) { console.log(error) }
  }

  public attachRateLockFile(event) {
    this.attachment = event.target.files;
    this.commentAttachment = [];
    this.counts = 0;
    this.count = 0
    Object.values(this.attachment).forEach(e => {
      this.commentAttachment.push(e);
      this.counts++;
      this.count++;
    });
  }

  public viewAttachment() {
    if (this.commentAttachment[this.commentAttachment.length - 1].hasOwnProperty("path"))
      this.$refs.dfl["setInitialValuesForShowingSamples"](
        null,
        this.commentAttachment,
        null
      );
    else
      this.$refs.dfl["setInitialValuesForLocalFiles"](
        this.commentAttachment,
        true,
        "commentAttachment"
      );
  }

  mounted() {
    this.restoreDisableDates();
  }
}
