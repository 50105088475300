































import Axios from "axios";
import { Component, Vue, Prop } from "vue-property-decorator";
import { BASE_API_URL } from "@/config";

@Component({
  components: {
  },
})
export default class ConfirmUnassignBorrowerModal extends Vue {

  @Prop({ required: true })
  public selectedCommunication: any;

  public async unassignBorrowerFromCommunication(communicationId: string) {
    try {
      this.$store.state.wemloLoader = true;

      await Axios.post(BASE_API_URL + "communication/unassignBorrowerFromCommunication", {
        communicationId,
      });

      this.$modal.hide('confirmUnassignBorrower');

      /**
       * Emit change event to reload data on parent
       */
      this.$emit('change');
    } catch (error) {
      console.log(error);
    } finally {
      this.$store.state.wemloLoader = false;
    }
  }
}
