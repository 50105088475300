import { Component, Vue } from "vue-property-decorator";
import { BASE_API_URL } from '@/config';
import Axios from 'axios';


@Component
export default class CommunicationMixin extends Vue {
    public comments: any = null;
    public searchableComments: any = null;
    public commentIndex = null;
    public fileExt = null;
    public attachmentToDisplay = null;
    public playingAudioIndex = null;
    public finalPlayingAudioIndex = null;
    public messageText = "";
    public audioPlaying = false;
    public callLogs: any = []
    public searchableCallLogs: any = null;
    public communicationdotLoader = false
    public attachment = [];
    public fileArray = [];
    public fileIndex = 0;
    public showFileArray = [];
    public showNext = false;
    public showPrev = false;
    public dotLoader = false;
    public titleInformation = null;

    public get filteredComments() {
        const searchable = this.searchableComments?.toLowerCase()
        return searchable ? this.comments.filter(c => c.comment?.toLowerCase().includes(searchable)) : this.comments
    }

    public get computedCallLogs() {
        return this.callLogs.map(cl => {
            return {
                ...cl,
                computedFrom: cl.fromName === 'Unknown' && cl.type === 'wemloMessage' ? 'wemlo' : cl.fromName,
                computedTo: this.getCommunicationToUserName(cl.to),
            }
        })
    }

    public get filteredCallLogs() {
        const searchable = this.searchableCallLogs?.toLowerCase()
        return searchable ? this.computedCallLogs.filter(c => c.body?.toLowerCase().includes(searchable)) : this.computedCallLogs
    }

    public async openNavForCommunication() {
        let nav = document
            .getElementById("mySidenavForCommunication")
        nav.classList.add("nav_width");
        nav.style.display = 'block';
        nav.classList.add("d-block")   
        document.getElementById("overlay").style.display = "block";
    }

    public async closeNavForCommunication() {
        let nav = document
            .getElementById("mySidenavForCommunication")
        nav.classList.remove("nav_width");
        nav.classList.remove("d-block")   
        nav.style.display = 'none';
        document.getElementById("overlay").style.display = "none";
    }

    public async openNav(loanTxnId) {
        this.comments = null;
        document.getElementById("overlay").style.display = "block";
        let response = await Axios.post(
            BASE_API_URL + "wemloprocessor/fetchComments",
            {
                loanTxnId: loanTxnId
            });
        this.comments = response.data.comments;
        this.toggleCommentsNav("block");
        this.commentIndex = null
    }

    public toggleCommentsNav(val) {
        let nav = document.getElementById("wemlo-comment");
        nav.style.display = val;
        if (val == 'block') {
           nav.classList.add("nav_width");
           nav.classList.add("d-block")
        } else {
            nav.classList.remove("nav_width");
            nav.classList.remove('d-block')
        }
        document.getElementById("overlay").style.display = val;
    }

    public toggleData(i) {
        let commentElement = document.getElementById("comment_" + i);
        commentElement.style.display =
            commentElement.style.display == "none" ? "block" : "none";
    }

    public attachmentOpen(data) {
        let reviewDoc = { fileHistory: [{ files: data.attachments, status: 'Rejected', commentPath: 0 }] }
        let comment: any;
        if (data.hasOwnProperty('body')) {
            comment = [{ comment: data.body }];
        }
        else { comment = [{ comment: data.comment }] }
        if (data.attachments.length > 0) {
            this.$refs.dfd['setInitialValues']([], comment, reviewDoc, false, null)
        } else {
            this.$refs.dfd['setInitialValuesForShowMessage'](data.body)
        }
    }

    public windowOpen() {
        window.open(this.attachmentToDisplay);
    }


    public truncate(str, length, ending) {
        if (str) {
            if (length == null) {
                length = 100;
            }
            if (ending == null) {
                ending = "...";
            }
            if (str.length > length) {
                return str.substring(0, length - ending.length) + ending;
            } else {
                return str;
            }
        } else return "";
    }

    public playOrPauseAudio(index) {
        let audio = this.$refs["aaudio" + index][0];

        if (audio.paused) {
            audio.play();
        } else {
            audio.pause();
        }
    }

    public async playAudio(index, event) {
        if (this.playingAudioIndex != null) {
            let audio = this.$refs["aaudio" + this.playingAudioIndex];
            this.finalPlayingAudioIndex = index;
            audio[0].pause();
        } else {
            this.playingAudioIndex = index;
        }
    }

    public stopPlayingAudio(index, event) {
        if (this.finalPlayingAudioIndex != null) {
            this.playingAudioIndex = this.finalPlayingAudioIndex;
            this.finalPlayingAudioIndex = null;
        } else {
            this.playingAudioIndex = null;
        }
    }

    public timeInLocalTimeString(date) {
        let event = new Date(date);
        return event.toLocaleTimeString("en-US");
    }

    public dateInProperFormat(date) {
        let event = new Date(date);
        let day = event.getDate() < 10 ? "0" + event.getDate() : event.getDate();
        let month =
            event.getMonth() < 10 ? "0" + event.getMonth() : event.getMonth();
        let year = event.getFullYear();
        return day + "-" + month + "-" + year;
    }

    public getCommunicationToUserName(users) {
        let userArray = [];
        users.forEach(element => {
            userArray.push(element.toName);
        });
        return userArray.toString();
    }

    public async getCommunicationHistory(loanTxnId) {
        try {
            this.communicationdotLoader = true;
            let response = await Axios.get(
                BASE_API_URL + "communication/getCommunicationHistory",
                {
                    params: {
                        loanTxnId: loanTxnId
                    }
                }
            );
            this.callLogs = response.data.data;
        } catch (error) {
            console.log(error);
        } finally {
            this.communicationdotLoader = false
        }
    }
}