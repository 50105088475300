


























import WemloFooter from "@/views/Footer.vue";
import PipeLineHeader from "@/views/pipeLineHeader/PipeLineHeader.vue";
import preRequisiteHeader from "@/views/preRequisiteHeader/PreRequisiteHeader.vue";
import WemloHeader from "@/views/WeMLOHeader.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    WemloHeader,
    WemloFooter,
    PipeLineHeader,
    preRequisiteHeader,
    AppSideNav: () => import("@/views/sideNav/AppSideNav.vue")
  }
})
export default class BrokerComponent extends Vue {
  componentKey: number = 0;

  public get hasAnsweredAll() {
    return this.$store.state.posSessionConfig.answeredAll;
  }

  public get hasSession() {
    return this.$store.state.sessionObject.token;
  }

  public get userType() {
    return this.$store.state.sessionObject.type;
  }

  public shouldShowSidenav(): boolean {
    if (!this.hasSession) {
      return false;
    }

    const blacklistRoutes = [
      'BorrowerBorrowerWelcome',
      'BorrowerPOSQuestionnaire',
      'Prerequisites',
      'MLOPrerequisites',
    ];

    return !blacklistRoutes.includes(this.$route.name);
  }

  public reloadComponent(): void {
    this.componentKey += 1;
  }
}
